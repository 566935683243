<template>
    <div class="Certificate">
        <div class="top">
            <img src="@/assets/zs.png" class="zs-img" alt="" />
            <div class="text">我的证书</div>
        </div>
        <div class="cer-container">
            <NoData v-if="!certificate.length" text="您还没有获得证书"></NoData>
            <div class="flex wrap">
                <div
                    class="mb-35"
                    :class="{ 'mr-82': (index + 1) % 3 != 0 }"
                    v-for="(item, index) in certificate"
                    :key="'img' + index"
                >
                    <div class="item" :id="'img' + index">
                        <img
                            :src="item.certificate.image"
                            class="item-img"
                            alt=""
                            @contextmenu.prevent=""
                            @dragstart.prevent=""
                        />
                        <div class="name">{{ item.qywx_user.name }}</div>
                        <!-- <div class="desc">在2020年度完成新干部培训，被评为</div> -->
                        <!-- <div class="call">“优秀新干部”</div> -->
                        <div class="time">
                            {{
                                item.certificate.created
                                    .split(" ")[0]
                                    .replaceAll("-", ".")
                            }}
                        </div>
                    </div>
                    <div class="flex-jsc mt-16">
                        <el-image
                            class="dow mr-25 click"
                            :src="look_img_icon"
                            :preview-src-list="imgSrcList"
                            @click="lookImg('img' + index)"
                        >
                        </el-image>
                        <img
                            src="@/assets/ic_xz@2x.png"
                            class="dow click"
                            alt=""
                            @click="dowImg('img' + index)"
                        />
                    </div>
                </div>
            </div>
            <div class="flex-jsc mt-32 mb-6">
                <Pagination
                    :pageSize="9"
                    :total="total"
                    @getPage="getPage"
                ></Pagination>
            </div>
        </div>
        <a id="download" :href="downImg" download hidden>Download file</a>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    import domtoimage from "dom-to-image-more";
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Certificate",
        components: {
            Pagination,
            NoData,
        },
        data() {
            return {
                look_img_icon: require("@/assets/ic_ck.png"),
                certificate: [],
                total: 0,
                imgSrcList: [""], //放大图片列表
                downImg: "",
            };
        },
        mounted() {
            window.localStorage.setItem("myNavActive", 6);
            this.$store.commit("switchMyNav", 6);
            this.getData();
        },
        methods: {
            //下载图片
            dowImg(e) {
                domtoimage
                    .toPng(document.getElementById(e))
                    .then((canvas) => {
                        this.imgSrcList = [];
                        let oImg = new Image();
                        oImg.src = canvas; // 导出图片
                        this.downImg = oImg.src;
                        this.$nextTick(() => {
                            document.getElementById("download").click();
                        });
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            },
            //放大图片
            lookImg(e) {
                domtoimage
                    .toPng(document.getElementById(e))
                    .then((canvas) => {
                        this.imgSrcList = [];
                        let oImg = new Image();
                        oImg.src = canvas; // 导出图片
                        this.imgSrcList.push(oImg.src);
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            },
            //分页
            getPage(e) {
                this.getData(e);
            },
            //获取证书
            getData(page = 1) {
                this.$https
                    .get("/api/user/certificate/list", { page, limit: 9 })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.certificate = res.data.list;
                        this.total = res.data.total_count;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.Certificate {
    width: 100%;
    .top {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        padding: 21px 14px;
        display: flex;
        align-items: center;
        .zs-img {
            width: 30px;
            height: 30px;
        }
        .text {
            font-size: 18px;
            font-weight: 500;
            color: #2d2d2d;
            margin-left: 12px;
        }
    }
    .cer-container {
        padding: 29px 49px;
        background: white;
        border-radius: 10px;
        margin-top: 19px;
        .item {
            position: relative;
            width: 236px;
            height: 337px;
            // margin-bottom: 75px;
            .item-img {
                width: 100%;
                height: 100%;
            }
            .name {
                position: absolute;
                top: 94px;
                left: 29px;
                font-size: 12px;
                font-weight: 500;
                color: #000000;
                width: 75px;
                background: white;
                text-align: center;
            }
            .desc {
                position: absolute;
                top: 117px;
                width: 170px;
                left: 29px;
                text-indent: 2em;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                background: white;
            }
            .call {
                font-size: 24px;
                font-family: HYNuoMiTuanW;
                text-align: center;
                color: #ea4e7b;
                background: white;
                position: absolute;
                top: 163px;
                width: 180px;
                left: 0px;
                right: 0;
                margin: auto;
                text-align: center;
            }
            .time {
                position: absolute;
                top: 275px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                right: 35px;
                background: white;
                width: 72px;
                text-align: right;
            }
        }
        .dow {
            width: 92px;
            height: 34px;
        }
    }
}
</style>